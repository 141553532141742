import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const Card = ({ item }) => {
  if (!item) {
    return
  }
  return (
    <div className="uk-card uk-card-small uk-card-hover uk-flex uk-flex-column uk-flex-stretch uk-overflow-hidden">
      <div className="uk-card-body" style={{ padding: "10px 10px 0 10px" }}>
        {item.capacityText ? (
          <p className="uk-text-center uk-text-bold text-primary">
            {item.capacityText}
          </p>
        ) : (
          <></>
        )}
        <p className="uk-card-title" title={item.name}>
          {item.name}
        </p>
        <p>
          <span className="uk-text-bold">Model: </span>
          {item.modelNumber || item.productSeries}
        </p>
      </div>
      <div
        className="uk-card-media-bottom uk-flex uk-flex-1 uk-flex-middle"
        style={{ padding: "10px" }}
      >
        {item.image && (
          <GatsbyImage
            className="uk-margin-auto"
            image={item.image.childImageSharp.gatsbyImageData}
            alt={item.name}
          />
        )}
      </div>
      <span className="checked-icon" data-uk-icon="icon:check; ratio: 2"></span>
      {(item.cardFeatures || []).length ? (
        <div className="features" style={{}}>
          <div className="uk-flex uk-flex-center uk-text-left uk-text-small">
            <ul
              className="uk-list uk-list-disc"
              style={{ marginBottom: "10px" }}
            >
              {item.cardFeatures.map((item, i) => (
                <li style={{ margin: 0 }} key={`card_feature_${i}`}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Card
