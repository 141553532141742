import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Card from "./card"

const Products = ({ items, category }) => {
  return (
    <div className="uk-section-xsmall">
      <div
        className="uk-grid-match uk-flex uk-flex-wrap uk-flex-center"
        data-uk-grid
      >
        {items.map((item, i) => {
          return (
            <Link
              to={`/category/${category._id}/products/${item._id}`}
              key={`product__${item._id}`}
              className={`uk-link-reset uk-width-1-${items.length}`}
              style={{ maxWidth: "400px", minWidth: "280px" }}
            >
              <Card item={item} />
            </Link>
          )
        })}
      </div>
    </div>
  )
}

Products.propTypes = {
  items: PropTypes.array,
  category: PropTypes.object,
}

export default Products
