import log from "loglevel"
import PropTypes from "prop-types"
import React from "react"
import ProductsComponent from "./products"
import TitleWithCopyLink from "./title-copy-link"

const CompatibleProducts = ({ title, products, images, category }) => {
  if (!products || !products.length) {
    return <></>
  }

  // Filter by the category
  if (!!category) {
    products = products.filter(product => product.category === category)
  }

  products.forEach(product => {
    product.image = (
      (
        images.find(
          edge =>
            edge.node.parent.name.includes(
              product.productSeries.replace("/", ":")
            ) &&
            edge.node.parent.name.includes(product.subcategory.toLowerCase()) &&
            edge.node.parent.name.toLowerCase().includes("landing")
        ) || {}
      ).node || {}
    ).parent
    if (!product.image) {
      product.image = (
        (
          images.find(
            edge =>
              edge.node.parent.name.includes(
                product.productSeries.replace("/", ":")
              ) && edge.node.parent.name.toLowerCase().includes("landing")
          ) || {}
        ).node || {}
      ).parent
    }
    if (!product.image) {
      product.image = (
        (
          images.find(edge =>
            edge.node.parent.name.includes(
              product.productSeries.replace("/", ":")
            )
          ) || {}
        ).node || {}
      ).parent
    }
  })

  log.info(category, products)

  if (!products || !products.length) {
    return <></>
  }

  return (
    <div className="uk-section-xsmall  uk-text-center">
      <ul data-uk-accordion>
        <li>
          <span
            className="uk-accordion-title"
            style={{ background: "none", cursor: "pointer" }}
            href="#"
          >
            {/* <h3 className="uk-text-bold uk-text-center uk-margin-remove">
              {title}
            </h3> */}
            <TitleWithCopyLink
              title={title}
              type="h3"
              badge={products.length}
            />
          </span>
          <div className="uk-accordion-content uk-padding">
            <ProductsComponent
              items={products || []}
              category={{ _id: "controls" }}
              id={category?.toLowerCase()}
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

CompatibleProducts.propTypes = {
  category: PropTypes.string,
  products: PropTypes.array,
  title: PropTypes.string,
  images: PropTypes.array,
}

CompatibleProducts.defaultProps = {
  category: "",
  title: "",
  items: [],
  images: [],
}

export default CompatibleProducts
